import React, { memo, useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { IconEnum, SvgIcon } from '~/common/components/ui-elements';
import Carousel from 'react-multi-carousel';
import { CarouselProps } from 'react-multi-carousel/lib/types';
import { colorBlack, colorGrey, colorPrimary, hexToRgbA } from '~/common/utils';

interface CustomCarouselProps extends CarouselProps {
  carouselDotColor?: string;
  carouselActiveDotColor?: string;
  className?: string;
  dotClassName?: string;
  carouselArrowColor?: string;
  goToSlide?: number;
  goToSlideTrigger?: number;
  borderedArrows?: boolean;
  renderButtonGroupInside?: boolean;
  renderDotsInside?: boolean;
}

export const CustomCarousel = memo(
  ({
    children,
    className,
    dotClassName,
    carouselArrowColor,
    carouselDotColor,
    carouselActiveDotColor,
    goToSlide,
    goToSlideTrigger,
    itemClass,
    borderedArrows,
    renderDotsInside,
    renderButtonGroupInside,
    ...rest
  }: CustomCarouselProps) => {
    const carouselRef: React.LegacyRef<Carousel> | undefined = useRef(null);

    useEffect(() => {
      if (goToSlide) carouselRef?.current?.goToSlide(goToSlide);
    }, [children]);

    useEffect(() => {
      if (goToSlideTrigger !== undefined)
        carouselRef?.current?.goToSlide(goToSlide || 0);
    }, [goToSlideTrigger]);

    const ButtonGroup = ({ next, previous, ...bgRest }: any) => {
      const {
        carouselState: { currentSlide, totalItems, slidesToShow },
      } = bgRest;

      return rest.arrows ? (
        <>
          {
            <div
              className={cn(styles.CarouselArrow, styles.CarouselArrowLeft, {
                [styles.CarouselArrowDisabled]: !(currentSlide > 0),
                [styles.CarouselArrowInside]: renderButtonGroupInside,
              })}
              onClick={() => previous()}
              style={{
                ...(borderedArrows
                  ? {
                      boxShadow: `0px 0px 1px 1px ${hexToRgbA(
                        `#${carouselArrowColor || colorBlack}`
                      )}`,
                      lineHeight: 1,
                      padding: '10px 11px',
                    }
                  : renderButtonGroupInside
                  ? {
                      transform: 'translate(0%, -100%)',
                    }
                  : {}),
              }}
            >
              <SvgIcon
                type={IconEnum.chevronLeft}
                size={borderedArrows ? 1 : 1.75}
                color={`#${carouselArrowColor || colorBlack}`}
              />
            </div>
          }
          {
            <div
              className={cn(styles.CarouselArrow, styles.CarouselArrowRight, {
                [styles.CarouselArrowDisabled]: !(
                  currentSlide <
                  totalItems - slidesToShow
                ),
                [styles.CarouselArrowInside]: renderButtonGroupInside,
              })}
              onClick={() => next()}
              style={{
                ...(borderedArrows
                  ? {
                      boxShadow: `0px 0px 1px 1px ${hexToRgbA(
                        `#${carouselArrowColor || colorBlack}`
                      )}`,
                      lineHeight: 1,
                      padding: '10px 11px',
                    }
                  : renderButtonGroupInside
                  ? {
                      transform: 'translate(0%, -100%)',
                    }
                  : {}),
              }}
            >
              <SvgIcon
                type={IconEnum.chevronRight}
                size={borderedArrows ? 1 : 1.75}
                color={`#${carouselArrowColor || colorBlack}`}
              />
            </div>
          }
        </>
      ) : (
        <></>
      );
    };

    const CustomDot = ({ index, onClick, active }: any) => {
      return (
        <li
          key={index}
          className={cn(styles.CarouselDotContainer, {
            [styles.CarouselDotActive]: active,
          })}
          onClick={() => onClick()}
        >
          <div
            className={styles.CarouselDot}
            style={{
              backgroundColor: `#${
                active
                  ? carouselActiveDotColor || colorGrey
                  : carouselDotColor || colorPrimary
              }`,
            }}
          />
        </li>
      );
    };

    return (
      <Carousel
        {...rest}
        containerClass={cn(styles.CarouselContainer, className)}
        dotListClass={cn(
          styles.CarouselDots,
          {
            [styles.CarouselDotsInside]: renderDotsInside,
          },
          dotClassName
        )}
        sliderClass={styles.CarouselSlider}
        itemClass={cn(styles.CarouselItem, itemClass, {
          [styles.CarouselItemPartial]: rest?.partialVisible || false,
        })}
        ref={carouselRef}
        responsive={rest.responsive}
        arrows={false}
        showDots={rest.showDots}
        renderDotsOutside
        customButtonGroup={<ButtonGroup />}
        renderButtonGroupOutside
        removeArrowOnDeviceType={rest.removeArrowOnDeviceType}
        customDot={<CustomDot />}
      >
        {children}
      </Carousel>
    );
  }
);
