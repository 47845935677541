import React, { useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { Button, TooltipDisclaimer } from '~/common/components/ui-elements';
import { IReview, IReviewsProps } from '~/common/models/interfaces/reviews';
import { CustomCarousel } from '../ui-elements/custom-carousel';
import { desktopMin, tabletMax } from '~/common/utils';
import { useRTETooltipPositionCorrector } from '~/common/hooks/use-rteTooltipPositionCorrector';

const breakpoints = {
  desktop: {
    breakpoint: { max: 4000, min: desktopMin },
    items: 1,
  },
  mobile: {
    breakpoint: { max: tabletMax, min: 0 },
    items: 1,
  },
};

export const Reviews: React.FC<IReviewsProps> = ({
  id,
  reviewList,
  heading,
  headerDisclaimer,
}) => {
  const [isMobile, setIsMobile] = useState(
    useMediaQuery(
      { query: `(max-width: ${tabletMax}px)` },
      undefined,

      (matches) => {
        setIsMobile(matches);
      }
    )
  );
  useRTETooltipPositionCorrector(isMobile);

  const showArrows = (list: IReview[]) => {
    if (!(list.length > 0)) return false;
    return list.length > 1;
  };

  const renderReviews = (list: IReview[]) => {
    return list.map((review, idx) => {
      const _date = review.reviewDate ? new Date(review.reviewDate) : undefined;
      const _dateFormatted = _date
        ? _date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
          })
        : undefined;

      return (
        <div key={idx} className={styles.Review}>
          <div
            className={styles.Excerpt}
            dangerouslySetInnerHTML={{ __html: review.excerpt as string }}
          />
          <div className={styles.ReviewDetails}>
            {review.author && _dateFormatted
              ? `${review.author} - ${_dateFormatted}`
              : _dateFormatted || review.author}
          </div>
          <div>
            <Button
              className={styles.ReviewUrl}
              type="text"
              href={review.reviewUrl?.url}
              target={review.reviewUrl?.target || '_self'}
            >
              {review.reviewUrl?.name}
            </Button>
          </div>
        </div>
      );
    });
  };

  if (!(reviewList && reviewList.length > 0)) return <></>;

  return (
    <div
      key={id}
      id={`cid-${id}`}
      className={cn('full-device-width', styles.ReviewsContainer)}
    >
      <MediaQuery minWidth={0}>
        <div className={styles.Header}>
          <div
            className={styles.HeaderText}
            dangerouslySetInnerHTML={{ __html: heading as string }}
          />
          {headerDisclaimer && (
            <TooltipDisclaimer
              triggerClassName={styles.HeaderDisclaimerTrigger}
              disclaimer={headerDisclaimer}
            />
          )}
        </div>
        <div className={styles.Reviews}>
          <CustomCarousel
            responsive={breakpoints}
            showDots={isMobile && showArrows(reviewList)}
            removeArrowOnDeviceType={['mobile']}
            arrows={!isMobile && showArrows(reviewList)}
          >
            {renderReviews(reviewList)}
          </CustomCarousel>
        </div>
      </MediaQuery>
    </div>
  );
};
